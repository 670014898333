<template>
    <div class="v-oral-history-index">
        <div class="view-content">
            <main-navigation />
            <column-list-index :items="items" />
        </div>
    </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue';
import ColumnListIndex from '@/components/ColumnListIndex.vue'

import { fetchOralHistoryEntries } from '../http/fetchApi';

export default {
    name: 'OralHistoryIndex',
    components: {
        MainNavigation,
        ColumnListIndex
    },
    props: {
        slug: String,
    },
    data() {
        return {
            items: []
        }
    },
    mounted() {
        this.fetchEntries();
    },
    methods: {
        async fetchEntries() {
            const { data } = await fetchOralHistoryEntries();
            this.items = data;

            // set header data
            this.$globalData.setHeaderData({
                title: 'F+F 1971 - Oral History'
            });
        },
    }
}
</script>
